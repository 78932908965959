#root {
  min-height: 80vh;
  background-color: whitesmoke;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  background-color: whitesmoke;
}

.parallax {
  overflow: hidden;
  background-color: whitesmoke;

  position: relative;
  /* The image used */
  background-image: url(./pics/backpick4.png);
  /* Full height */
  min-height: 100vh;
  /* min-height: calc(100vh - 44px); */
  /* Create the parallax scrolling effect */
  background-attachment: fixed;

  background-position: center;
  background-repeat: no-repeat;
  /* background-size: 100% 100% */
  z-index: 1;
  background-size: 100vw 100vh;
}

.book-top {
  margin: 0;
  padding: 0;
  background-color: whitesmoke;
  background-image: url("../src/pics/bgtop.png");
  background-repeat: no-repeat;
  position: fixed;
  background-position: top;
  z-index: 5;
  height: 22px;
  width: 100%;
  left: 0;
  background-size: 100vw 100%;
}

.book-bot {
  background-color: whitesmoke;
  background-image: url("../src/pics/bgbot.png");
  background-repeat: no-repeat;
  background-repeat: no-repeat;
  position: fixed;
  background-position: bottom;
  z-index: 5;
  height: 23px;
  width: 100%;
  bottom: 0;
  left: 0;
  background-size: 100vw 100%;
}

.book-bot-high {
  background-image: url("../src/pics/bg_bot_high.png");
  background-repeat: no-repeat;
  background-repeat: no-repeat;
  position: fixed;
  background-position: bottom;
  z-index: -2 !important;
  height: 455px;
  width: 100%;
  bottom: 0;
  left: 0;
  background-size: 100vw 100%;
}

.transparent {
  background-color: transparent;
}

.clickable {
  display: inline-block;
}

.clickable:hover {
  color: darkred;
  transform: scale(1.05);
  cursor: pointer;
  transform-origin: center;
}

.clickable2 {
  display: inline-block;
}

.clickable2:hover {
  color: darkred;
  transform: scale(1.02);
  cursor: pointer;
  transform-origin: center;
}

@media (max-width: 575.98px) {
  /* Extra small screens */
  .parallax {
    background-size: 95vw 100%;
  }

  .book-top {
    background-size: 95vw 100%;
  }
  .book-bot {
    background-size: 95vw 100%;
  }

  .book-bot-high {
    background-size: 95vw 100%;
  }

  #elke {
    transform: translateY(0) !important;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  /* Small screens */
  .parallax {
    background-size: 540px 100%;
  }

  .book-top {
    background-size: 540px 100%;
  }

  .book-bot {
    background-size: 540px 100%;
  }

  .book-bot-high {
    background-size: 540px 100%;
  }
  #elke {
    transform: translateY(0) !important;
  }

}

@media (min-width: 768px) and (max-width: 991.98px) {
  /* Medium screens */
  .parallax {
    background-size: 720px 100%;
  }

  .book-top {
    background-size: 720px 100%;
  }

  .book-bot {
    background-size: 720px 100%;
  }

  .book-bot-high {
    background-size: 720px 100%;
  }


}

@media (min-width: 992px) and (max-width: 1199.98px) {
  /* Large screens */
  .parallax {
    background-size: 960px 100%;
  }

  .parallax-background {
    background-size: 960px 100%;
  }
  .book-top {
    background-size: 960px 100%;
  }

  .book-bot {
    background-size: 960px 100%;
  }

  .book-bot-high {
    background-size: 960px 100%;
  }
}

@media (min-width: 1200px) and (max-width: 1399.98px) {
  /* Extra large screens */
  .parallax {
    background-size: 1140px 100%;
  }

  .parallax-background {
    background-size: 1140px 100%;
  }

  .book-top {
    background-size: 1140px 100%;
  }

  .book-bot {
    background-size: 1140px 100%;
  }

  .book-bot-high {
    background-size: 1140px 100%;
  }
  #content {
    padding-left: 25px !important;
  }
}

@media (min-width: 1400px) {
  /* Extra extra large screens */

  .parallax {
    background-size: 1320px 100%;
  }

  .parallax-background {
    background-size: 1320px 100%;
  }

  .book-top {
    background-size: 1320px 100%;
  }

  .book-bot {
    background-size: 1320px 100%;
  }

  .book-bot-high {
    background-size: 1320px 100%;
  }

  #content {
    padding-left: 25px !important;
  }
}
