/* $white: #ffffff;

$theme-colors: (
    "light":      #5b94cc,
    "dark":       #b33162,
    "primary":    #211d59,
    "secondary":  #364687,
    "info":       #98fae0,
    "accent1":    #1e262e,
    "accent2":    #4f5256,
    "accent3":    #617f9d,
    "success":    #26965d,
    "warning":    #dcc829,
    "danger":     #e72715,
);
 */

 $theme-colors: (
    "light":      #e6dfdc,
    "dark":       #181913,
    "primary":    #dd2727,
    "secondary":  #831717,
    "info":       #c17d7d,
    "accent1":    #6d4748,
    "accent2":    #8b6871,
    "accent3":    #a89da6,
    "success":    #62a523,
    "warning":    #fec06f,
    "danger":     #f01367,
);
$link-color: map-get($theme-colors, "secondary");
$body-bg:  whitesmoke;



@import 'bootstrap';
@import url('https://fonts.googleapis.com/css2?family=Quicksand&display=swap');
